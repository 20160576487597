@import "../theming/xpath-base.scss";
@import "../theming/material.scss";
@import "../theming/misc/tags.scss";
@import "../theming/misc/checkbox.scss";
@import "../theming/misc/custom-material.scss";
@import "../theming/misc/datetime-picker.scss";
@import "../theming/bootstrap.scss";
@import "~quill/dist/quill.snow.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .status {
    @apply inline-block rounded p-2 font-medium w-full text-center text-white uppercase;
  }
  .btn-primary {
    @apply py-2 px-5 text-nowrap bg-primary border border-primary flex items-center justify-center text-white font-medium rounded shadow-md hover:opacity-80 focus:outline-none  gap-1;
  }
  .btn-primary[disabled] {
    opacity: 0.7;
    cursor: not-allowed;
  }
  .btn-outline-primary {
    @apply py-2 px-5 text-nowrap bg-white border-primary border flex items-center justify-center font-medium text-primary rounded shadow-md hover:bg-primary hover:text-white gap-1;
  }
  .btn-outline-none-primary {
    @apply flex font-medium text-nowrap text-primary text-sm cursor-pointer items-center gap-1;
  }
  .btn-secondary {
    @apply py-2 px-5 text-nowrap bg-secondary flex items-center justify-center text-white font-medium rounded shadow-md hover:opacity-80 focus:outline-none gap-1;
  }
  .btn-outline-secondary {
    @apply py-2 px-5 text-nowrap bg-white border-secondary border flex items-center justify-center font-medium text-secondary rounded shadow-md hover:bg-secondary hover:text-white gap-1;
  }
  .btn-danger {
    @apply py-2 px-5 text-nowrap bg-danger flex items-center justify-center text-white font-medium rounded shadow-md hover:opacity-80 focus:outline-none gap-1;
  }
  .btn-outline-danger {
    @apply py-2 px-5 text-nowrap bg-white border-danger border flex items-center justify-center font-medium text-danger rounded shadow-md hover:bg-danger hover:text-white gap-1;
  }
  .btn-icon {
    @apply w-4 mr-1.5 #{!important};
  }
  .text-icon {
    @apply mr-1 w-4 h-4 #{!important};
  }
  .button-bar {
    @apply mb-4 flex flex-wrap justify-end gap-3;
  }
  .display-field {
    @apply bg-gray-light text-secondary border border-gray rounded font-normal p-2 my-2.5 w-full;
  }
  .columns-2 {
    @apply flex flex-wrap w-1/2 px-3;
  }
  .box {
    @apply bg-white rounded shadow-md p-3 #{!important};
  }
  .box-header {
    @apply flex border-b align-middle justify-between p-3 pb-2.5;
  }
  .box-title {
    @apply text-lg  self-center;
  }
  .box-actions {
    @apply float-right flex align-middle;
  }
  .box-body {
    @apply flex flex-wrap py-6 #{!important};
  }
  .box-status {
    position: relative;

    &::after {
      height: 100%;
      width: 4px;
      position: absolute;
      left: 0;
      top: 0;
      content: " ";
    }

    &.status-in_progress::after {
      background-color: var(--color-yellow);
    }

    &.status-pending::after {
      background-color: var(--color-gray);
    }

    &.status-cancelled::after {
      background-color: var(--color-secondary);
    }

    &.status-done::after {
      background-color: var(--color-green);
    }
  }
  .dialog {
    @apply max-h-screen flex flex-col;
  }
  .dialog-header {
    @apply text-2xl font-bold border-b p-6 flex justify-between items-center #{!important};
  }
  .dialog-footer {
    @apply flex justify-end w-full border-gray-light overflow-y-auto min-h-24 border-t p-6 #{!important};
  }
  .dialog-body {
    @apply p-6 overflow-y-auto grow #{!important};
  }
  .link-secondary {
    @apply text-secondary no-underline cursor-pointer break-words hover:text-primary hover:underline #{!important};
  }
  .chips {
    @apply flex items-center bg-gray-light rounded-2xl p-2 mr-1.5 mb-1.5;
  }
  .field-grid {
    @apply w-full grid py-3 md:gap-x-4 md:grid-cols-2;
  }

  .panel-toggle-arrow {
    background-color: var(--color-gray-light);
    text-align: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;

    .keyboard-arrow {
      transition: transform 0.3s ease;

      &-up {
        transform: rotate(-180deg);
      }
    }
  }
}
/* rewrites until we remove bootstrap */
.mb-4 {
  margin-bottom: 1rem !important;
}
.mx-5 {
  margin-left: 1.25rem !important;
  margin-right: 1.25rem !important;
}
.ms-5 {
  margin-left: 1.25rem !important;
}
.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field
  .mdc-notched-outline__notch {
  border-right-style: hidden;
}
.mat-mdc-input-element {
  box-shadow: none !important;
}
