@media (width >= 1200px) {
  .mat-mdc-dialog-surface {
    width: 800px !important;
  }
}

@media (width <= 991px) {
  .mat-mdc-dialog-surface {
    width: 80vw !important;
  }
}

.mat-mdc-menu-content {
  padding: 0 !important;
}

.mat-mdc-menu-item.mdc-list-item {
  min-height: 16px !important;
  height: 36px !important;
  line-height: 14px !important;
  width: 215px;

  span {
    font-size: 14px !important;
    font-weight: 400 !important;
    color: #708295 !important;
  }
}

.card-title {
  overflow: hidden;
  height: 63px;
}

.task-selector-wrapper {
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

.white-label {
  .mat-mdc-chip-action-label {
    color: white !important;
  }
}

/* Change the border color of mat-form-field */
.mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-notched-outline__trailing {
  @apply border-gray #{!important};
}

/* Change font color of mat-option */
.mat-mdc-option {
  font-family: "Montserrat", sans-serif;
  @apply text-sm font-normal #{!important};
}

/* Change font color and size of action menu options */
.mat-mdc-menu-item span {
  font-family: "Montserrat", sans-serif;
  @apply text-sm text-secondary font-normal #{!important};
}

/* Change the background color on hover of the mat-expansion-panel-header */
.mat-expansion-panel-header.mat-expanded:hover,
.mat-expansion-panel-header:hover,
.mat-expansion-panel-header.mat-expanded:focus,
.mat-expansion-panel-header.mat-expanded:focus:not([aria-disabled="true"]),
.mat-expansion-panel
  .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled="true"]),
.mat-expansion-panel
  .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled="true"]),
.mat-expansion-panel
  .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled="true"]),
.mat-expansion-panel:not(.mat-expanded)
  .mat-expansion-panel-header:hover:not([aria-disabled="true"]),
.mat-expansion-panel-header.mat-expanded:hover:not([aria-disabled="true"]) {
  background-color: #fff !important;
}
/* Keep height of mat-expansion-panel-header constant */
.mat-expansion-panel {
  .mat-expansion-panel-body {
    padding: 0;
  }
  .mat-expansion-panel-header {
    height: auto;
  }
  .mat-content.mat-content-hide-toggle {
    margin-right: 0;
  }
}
/* This allows tables inside expansion panels to be scrollable horizontally */
.mat-expansion-panel-content {
  @apply overflow-auto #{!important};
}
/* Change the color of the mat-expansion-indication */
.mat-expansion-indicator::after {
  @apply border-gray #{!important};
}

/* Change the padding of the mat-step containers */
.mat-horizontal-stepper-header {
  @apply px-1 #{!important};
}
.mat-horizontal-content-container {
  @apply p-0 #{!important};
}
/* For smaller screens where the top menu doesn't fit entirely, align the arrows with the text */
.mat-mdc-tab-header {
  @apply items-center;
}
/* Set up spacing under mat error */
.mat-mdc-form-field-subscript-wrapper {
  // @apply pb-2.5;
}
.mat-mdc-form-field-error-wrapper {
  @apply pl-0 #{!important};
}
.phone-field {
  .mat-mdc-text-field-wrapper {
    padding-left: 0px;
  }
}
.ngx-mat-input-tel-mat-menu-panel .mat-mdc-menu-content {
  max-height: 30vh;
}
.icon-button {
  &.sm {
    --button-size: 24px;
    --button-padding: 0px;

    --button-icon-size: 16px;
  }

  width: var(--button-size) !important;
  height: var(--button-size) !important;
  padding: var(--button-padding) !important;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;

  & > *[role="img"] {
    width: var(--button-icon-size);
    height: var(--button-icon-size);
    font-size: var(--button-icon-size);

    svg {
      width: var(--button-icon-size);
      height: var(--button-icon-size);
    }
  }

  .mat-mdc-button-touch-target {
    width: var(--button-size) !important;
    height: var(--button-size) !important;
  }
}
.cdk-overlay-transparent-backdrop {
  background-color: rgba(0, 0, 0, 0) !important;
}
.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drop-list-dragging .mat-row:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
